import { put, call, takeLatest } from 'redux-saga/effects';
import {
  GET_PLANS_BY_CLIENT_ID,
  getPlansByClientIdSuccessAction,
  getPlansByClientIdErrorAction,
  setSelectedPlanForClientId,
  GET_MEETINGDETAILS_BY_MEETING_ID,
  getMeetingDetailsByMeetingIdSuccessAction,
  getMeetingDetailsByMeetingIdErrorAction,
  setMeetingDetailsLoadingAction,
  saveAndUpdateMeetingDetailsByMeetingIdAction,
  saveAndUpdateMeetingDetailsByMeetingIdSuccessAction,
  saveAndUpdateMeetingDetailsByMeetingIdErrorAction,
  updateUserMobileAction,
  updateUserMobileSuccessAction,
  updateUserMobileErrorAction,
  SAVE_MEETINGDETAILS_BY_MEETINGID,
  GET_USERDETAILS_BY_EMAIL_ID,
  getUserInformationByEmailIdSuccessAction,
  getUserInformationByEmailIdErrorAction,
  UPDATE_USER_MOBILE,
} from '../actions/plansAction';
import {
  setPlansDetailsLoadingAction,
  getInvestmentProductsByClientIdSuccessAction,
} from '../actions/plannerDetailsAction';
import { getPlansByClientId, getUserInformationByEmailId } from '../../services/dashboardSvc';
import { getMeetingsByMeetingId, saveAndUpdateMeetingDetailsByMeetingId, updateMobileNumber } from '../../services/dashboardSvc';
import { getSortedPlansByModifiedDate, getSortedMeetingsByDate } from '../../utils/dateTimestampTransformer';

function* triggerGetPlansByClientId(payload) {
  try {
    yield put(getInvestmentProductsByClientIdSuccessAction([])); //clearing investment products used in solutions => investment page on client change
    const { status, data: plansData } = yield call(getPlansByClientId, payload);
    if (status === 200) {
      const sortedPlans = getSortedPlansByModifiedDate(plansData);
      const plans = sortedPlans.map((d) => {
        return {
          label: d.planName,
          value: d.planId,
        };
      });
      yield put(getPlansByClientIdSuccessAction(plans));
      if (plans.length > 0) {
        yield put(setSelectedPlanForClientId(plans[0].value));
        yield put(setPlansDetailsLoadingAction(true)); //setting this to simulate on plan change or horizon change
      }
    } else {
      yield put(getPlansByClientIdErrorAction([]));
    }
  } catch (err) {
    yield put(getPlansByClientIdErrorAction([]));
  }
}

function* triggerGetMeetingDetailsByMeetingId(request) {
  try {
    const { status, data: meetingsData } = 
      yield call(getMeetingsByMeetingId, request.payload);
    if (status === 200) {
      const meetingDetail = meetingsData;
      yield put(getMeetingDetailsByMeetingIdSuccessAction(meetingDetail));
    } else {
      yield put(getMeetingDetailsByMeetingIdErrorAction({}));
    }
  } catch (err) {
    yield put(getMeetingDetailsByMeetingIdErrorAction({}));
  }
}

function* triggerSaveMeetingDetailsByMeetingId(request) {
  try {
    yield put(setMeetingDetailsLoadingAction(true));
   // sending version from calling fn, so had to use ...rest for planDetailsByPlanId as it is named differently at different places
    const { status, data } = yield call(saveAndUpdateMeetingDetailsByMeetingId,
      request?.payload // gives planDetailsByPlanId Object
    );
    if (status === 200) {
      yield put(setMeetingDetailsLoadingAction(false));
      yield put(
        saveAndUpdateMeetingDetailsByMeetingIdSuccessAction(request?.payload)
      );
    } else {
      yield put(setMeetingDetailsLoadingAction(false));
      yield put(saveAndUpdateMeetingDetailsByMeetingIdErrorAction(request?.payload));
    }
  } catch (err) {
    yield put(setMeetingDetailsLoadingAction(false));
    yield put(saveAndUpdateMeetingDetailsByMeetingIdErrorAction(request?.payload));
  }
}

function* triggerGetUserDetailsByEmailId(request) {
  try {
    const { status, data } = yield call(getUserInformationByEmailId, request.payload);
    if (status === 200) {
      const userDetails = data;
      yield put(getUserInformationByEmailIdSuccessAction(userDetails));
    } else {
      yield put(
        getUserInformationByEmailIdErrorAction({})
      );
    }
  } catch (err) {
    yield put(
      getUserInformationByEmailIdErrorAction({})
    );
  }
}

function* triggerUpdateMobileNumberByEmailId(request) {
  try {
    const { status, data } = yield call(updateMobileNumber,
      request?.payload
    );
    if (status === 200) {
      yield put(
        updateUserMobileSuccessAction(request?.payload)
      );
    } else {
      yield put(updateUserMobileErrorAction(request?.payload));
    }
  } catch (err) {
    yield put(updateUserMobileErrorAction(request?.payload));
  }
}

export function* watchPlansByClient() {
  yield takeLatest(GET_PLANS_BY_CLIENT_ID, triggerGetPlansByClientId);
  yield takeLatest(GET_MEETINGDETAILS_BY_MEETING_ID, triggerGetMeetingDetailsByMeetingId);
  yield takeLatest(SAVE_MEETINGDETAILS_BY_MEETINGID, triggerSaveMeetingDetailsByMeetingId);
  yield takeLatest(GET_USERDETAILS_BY_EMAIL_ID,triggerGetUserDetailsByEmailId);
  yield takeLatest(UPDATE_USER_MOBILE,triggerUpdateMobileNumberByEmailId);
}