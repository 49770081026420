import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { selectedPlanIdForClientIdSelector } from '../selectors/plannerDetailsSelector';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import history from '../history';
import { chatBotURL } from '../services/CONSTANTS';

const MiboT = () => {
  const [isLoading, setIsLoading] = useState(true);
  // const miboTUrl = 'https://ai-prod.greenrfinancialadvisors.com/';

  const selectedClientId = useSelector((state) =>
    selectedClientIdSelector(state)
  );
  const selectedPlan = useSelector((state) =>
    selectedPlanIdForClientIdSelector(state)
  );
  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedClientId && !selectedPlan) {
      history.push({ pathname: '/home' });
    }
  }, [selectedClientId, selectedPlan]);

  const renderMiboTContainer = () => {
    return (
      <div className="client_main product_main outer">
        <div className="carousel-container">
          <div key="left" className="carousel-itemm">
            <div className="inner">
              <div
                className={
                  'client_left product_left solution_tab_content build_action_height_full'
                }
              >
                <div className="product_left_content">
                  {isLoading && (
                    <CircularProgress
                      sx={{
                        color: '#a1a1a1',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                      }}
                      size={20}
                    />
                  )}
                  <iframe
                    src={chatBotURL}
                    title="Chatbot"
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    onLoad={handleIframeLoad}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="divider_line"></div>
          <div key="right" className="carousel-itemm"></div>
        </div>
      </div>
    );
  };

  return (
    <div className="main-content-wrapper">
      <div className="container-fluid main-content-wrapper-inner">
        <div className="row">{renderMiboTContainer()}</div>
      </div>
    </div>
  );
};

export default MiboT;
