/**
 * There can be only 1 or even more than 2 reducers.
 * combineReducers defines the structure of the store object.
 */
import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import plansReducer from './plansReducer';
import clientInfoReducer from './clientsInfoReducer';
import companiesInfoReducer from './companiesInfoReducer';
import PlannerDetailsReducer from './plansDetailsReducer';
import OutputResponseReducer from './outputResponseDetailsReducer';
import simualateBeforeAfterReducer from './simualateBeforeAfterReducer';
import ReportsReducer from './reportsReducer';
import PlaidUIReducer from './plaidUIReducer';

const appReducer = combineReducers({
  loggedInUser: UserReducer,
  plansReducer: plansReducer,
  clientInfoReducer: clientInfoReducer,
  companiesInfoReducer: companiesInfoReducer,
  PlannerDetailsReducer: PlannerDetailsReducer,
  OutputResponseReducer: OutputResponseReducer,
  simualateBeforeAfterReducer: simualateBeforeAfterReducer,
  reportsReducer: ReportsReducer,
  PlaidUIReducer: PlaidUIReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USER_DETAILS') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
