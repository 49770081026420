import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFormSelectOption,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
} from '../../helpers/ClientHelper';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

export const InvPropsContent = (props) => {
  const {
    state,
    concatContent,
    taxStateOptions,
    setLastStepInParent,
    formErrors,
    isFormValid,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleInvestments',
    mainTab: 'invProps',
    subTab: 'props',
    countVal: 'numInvProps',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.mainTab] &&
      formDetails[moduleObject.mainTab].prop?.name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Short name of home',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true, //isMandatory field
            formErrors,
            isAddNew
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {isAddNew || isEditable ? (
            <>
              {renderFormSelectOption(
                'text',
                'tlPropDomicile',
                'propDomicile',
                'Property domicile',
                taxStateOptions,
                moduleObject,
                state,
                props,
                true //isMandatory field
              )}
            </>
          ) : (
            <>
              {renderFormInputRow(
                'text',
                'tlPropDomicile',
                'propDomicile',
                'Property domicile',
                'textDropdown',
                moduleObject,
                state,
                props
              )}
            </>
          )}
          {renderFormInputRow(
            'text',
            'tlPurchasePrice',
            'purchasePrice',
            'Purchase price',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormDateField(
            'purchaseDate',
            'Purchase date',
            'text',
            moduleObject,
            state,
            props,
            'date'
          )}
          {renderFormInputRow(
            'text',
            'tlValue',
            'value',
            'Current value of home',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAppreciationRate',
            'appreciationRate',
            'Annual appreciation rate',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormDateField(
            'origOrRefiLoanDate',
            'Latest refinance (or original loan) date',
            'text',
            moduleObject,
            state,
            props,
            'date'
          )}
          {renderFormInputRow(
            'text',
            'tlOrigOrRefiLoanAmount',
            'origOrRefiLoanAmount',
            'Loan amount',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlLoanAmountOutstanding',
            'loanAmountOutstanding',
            'Current balance',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlFixedIntRateg',
            'fixedIntRate',
            'Annual fixed interest rate',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlLoanTerm',
            'loanTerm',
            'Loan term years',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTaxBasis',
            'taxBasis',
            'Base value for property tax estimation',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTaxRate',
            'taxRate',
            'Property tax rate',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTaxBasisGrowth',
            'taxBasisGrowth',
            'Annual growth of base value for property tax estimation',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAggMaintenanceExp',
            'aggMaintenanceExp',
            'Annual maintenance expense (do not include insurance)',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAggMaintenanceExpGrowth',
            'aggMaintenanceExpGrowth',
            'Annual growth of maintenance expense',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAnnualRent',
            'annualRent',
            'Annual rent',
            'currency',
            moduleObject,
            state,
            props,
            'isSpecialProp'
          )}
          {renderFormInputRow(
            'text',
            'tlAnnualRentGrowth',
            'annualRentGrowth',
            'Annual rent growth',
            'percentage',
            moduleObject,
            state,
            props,
            'isSpecialProp'
          )}
          {renderFormInputRow(
            'text',
            'tlPriorDeprAccumulated',
            'priorDeprAccumulated',
            'Depreciation accumulated',
            'currency',
            moduleObject,
            state,
            props,
            'isSpecialProp'
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default InvPropsContent;
