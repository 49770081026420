/* eslint-disable default-case */
import { API_CONFIG_ENV } from './CONSTANTS';

export const API_HOST_URL = (isPublic) => {
  switch (API_CONFIG_ENV) {
    case 'dev':
      break;
    // default:
    //   return 'https://api.greenrfinancial.com';
    //   return 'http://107.20.29.93:9090';
    //return 'https://api.greenrfinancial.com';
    case 'uacc':
    default:
      //return 'https://api.greenrfinancial.com';
      return isPublic
        ? 'https://auth-prod.greenrfinancialadvisors.com/public'
        : 'https://api-prod.greenrfinancialadvisors.com/private';
    //return 'https://greenrfinancial.net:9080'; // uncomment above for secured apis
  }
};

//  'https://api-prod.greenrfinancialadvisors.com/public'
//  'https://api-prod.greenrfinancialadvisors.com/private';
