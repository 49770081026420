export const getPlannerDetailsSelector = (state) => state.PlannerDetailsReducer;

const getClientPlansSelector = (state) => state.plansReducer;
const getClientsInfoSelector = (state) => state.clientInfoReducer;

export const plannerDetailsLoadingSelector = (state) =>
  getPlannerDetailsSelector(state).isPlanDetailsLoading;

export const simulatePlanDetailsLoadingSelector = (state) =>
  getPlannerDetailsSelector(state).isSimulatePlanDetailsLoading;

export const getIsClientViewSelected = (state) =>
  getClientPlansSelector(state).isClientView;

export const plannerDetailsSaveStatus = (state) =>
  getPlannerDetailsSelector(state).isPlanDetailsSaved;

export const plannerDetailsErrorStatus = (state) =>
  getPlannerDetailsSelector(state).planDetailsError;

export const plannerDetailsSimulateStatus = (state) =>
  getPlannerDetailsSelector(state).planDetailsSimulated;

export const beforeAfterAssetMixValuesSelector = (state) =>
  getPlannerDetailsSelector(state).beforeAfterAssetMixValues;

export const getAssetAllocationByProductIdSelector = (state) =>
  getPlannerDetailsSelector(state).assetMixValues;

export const getRecommendedAssetAllocationByClientIdSelector = (state) =>
  getPlannerDetailsSelector(state).recommendedAssetAllocationValues;

export const getInvestmentProductsByClientIdSelector = (state) =>
  getPlannerDetailsSelector(state).investmentProducts;

export const assetAllocationByProductIdLoadingSelector = (state) =>
  getPlannerDetailsSelector(state).assetAllocationByProductIdLoadingAction;

export const planDetailsByPlanIdSelector = (state) =>
  getPlannerDetailsSelector(state).planDetails;

export const selectedPlanIdForClientIdSelector = (state) =>
  getClientPlansSelector(state).selectedPlan;

export const selectedDisplayTermForClientIdSelector = (state) =>
  getClientPlansSelector(state).selectedDisplayTerm;

export const selectedHorizonTermForClientIdSelector = (state) =>
  getClientPlansSelector(state).selectedHorizonTerm;

export const allPlannerMenuItemsSelector = (state) =>
  getClientsInfoSelector(state).allMenuItems;

export const allTaxSlabsSelector = (state) =>
  getClientsInfoSelector(state).allTaxSlabs;

export const expenseFactorDetailsSelector = (state) =>
  getClientsInfoSelector(state).expenseFactorDetails;

export const selectedChronoTabItemSelector = (state) =>
  getClientPlansSelector(state).selectedChronoTabItem;

export const selectedChartSelector = (state) =>
  getClientPlansSelector(state).selectedChart;

export const getExpenseDetailsByLifestyleSelector = (state) =>
  getPlannerDetailsSelector(state).expenseDetailsByLifestyle;

export const getLifestyleSelector = (state) =>
  planDetailsByPlanIdSelector(state).moduleLifeStyle.infoWagesAndExpenseProfile
    .lifestyle;

export const getNumberOfRuns = (state) =>
  getClientPlansSelector(state).selectedNumberOfRuns;

export const getPushPinCharts = (state) =>
  getClientPlansSelector(state).pushPinCharts;

export const termLifeInsuranceProductsSelector = (state) =>
  getPlannerDetailsSelector(state).termLifeInsuranceProducts;

export const wholeLifeInsuranceProductsSelector = (state) =>
  getPlannerDetailsSelector(state).wholeLifeInsuranceProducts;

export const longTermCareInsuranceProductsSelector = (state) =>
  getPlannerDetailsSelector(state).longTermCareInsuranceProducts;

export const umbrellaInsuranceProductsSelector = (state) =>
  getPlannerDetailsSelector(state).umbrellaInsuranceProducts;

export const propertyInsuranceProductsSelector = (state) =>
  getPlannerDetailsSelector(state).propertyInsuranceProducts;

export const autoInsuranceProductsSelector = (state) =>
  getPlannerDetailsSelector(state).autoInsuranceProducts;

export const getClientsByAdvisorIdSelector = (state) =>
  getClientsInfoSelector(state).clients || [];

export const getPlansByClientIdSelector = (state) =>
  getClientPlansSelector(state).plans || [];

export const getMeetingsByMeetingIdSelector = (state) =>
getClientPlansSelector(state).meetingDetail || [];
