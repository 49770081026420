import React, { Component } from 'react';
import ChartContainer from '../ChartContainer';
import ChronoChartsContainer from '../ChronoChartsContainer';
import {
  setSubChartOptions,
  setBulletSeriesOptions,
  setBubbleChartOptions,
  setLollipopChartOptions,
} from '../../components/shared/ChartData';

const HomeChartComponent = ({ cta, charts }) => {
  const renderChartContainer = () => {
    const { heading, renderWhat } = cta;
    const { subtabName, tabName, chart } = renderWhat;
    const { notfullOffset, seriesType, typeIfTimeSeries } = chart;
    if (charts && charts.length > 0) {
      let pieChartsData = {};
      const selectedChartsData = charts.filter(
        (c) => c.tabName.toLowerCase() === tabName.toLowerCase()
      )[0];
      const selectedSubChartData =
        (selectedChartsData &&
          selectedChartsData.subtabs &&
          selectedChartsData.subtabs.length > 0 &&
          selectedChartsData.subtabs.filter(
            (f) => f.subtabName.toLowerCase() === subtabName.toLowerCase()
          )[0]) ||
        {};
      if (
        selectedSubChartData &&
        selectedSubChartData.timeSeries &&
        Object.keys(selectedSubChartData.timeSeries).length > 0
      ) {
        if (
          seriesType === 'timeseries' &&
          selectedSubChartData.timeSeries.main &&
          Object.keys(selectedSubChartData.timeSeries.main).length > 0 &&
          selectedSubChartData.timeSeries.sub &&
          selectedSubChartData.timeSeries.sub.length >= 0
        ) {
          if (typeIfTimeSeries === 'main') {
            return (
              <ChronoChartsContainer
                selectedChart={subtabName}
                chartsData={selectedSubChartData.timeSeries}
                isDashboard={false}
                isHome={true}
                series={typeIfTimeSeries}
              />
            );
          } else if (
            typeIfTimeSeries === 'sub' &&
            selectedSubChartData.timeSeries.sub.length > 0
          ) {
            const yAxisLeftLabel =
              selectedSubChartData.timeSeries.main.yaxisLeftLabel &&
              selectedSubChartData.timeSeries.main.yaxisLeftLabel !== ''
                ? selectedSubChartData.timeSeries.main.yaxisLeftLabel
                : '';
            pieChartsData = setSubChartOptions(
              selectedSubChartData.timeSeries.sub[notfullOffset],
              'cta',
              yAxisLeftLabel
            );
            return (
              <ChartContainer
                chartType={'pie'}
                selectedChart={heading}
                options={pieChartsData}
              />
            );
          }
        }
      } else if (
        seriesType === 'bullet' &&
        selectedSubChartData &&
        selectedSubChartData.bullet &&
        selectedSubChartData.bullet.bulletSeries &&
        selectedSubChartData.bullet.bulletSeries.length > 0
      ) {
        return (
          <>
            {selectedSubChartData.bullet.bulletSeries.map((m, i) => {
              const { chartTitle = '' } = selectedSubChartData.bullet || {};
              return (
                <ChartContainer
                  chartType="bullet"
                  chartTitle={i === 0 ? chartTitle : undefined}
                  selectedChart={'bullet'}
                  options={setBulletSeriesOptions(m, i)}
                />
              );
            })}
          </>
        );
      } else if (
        seriesType === 'bubble' &&
        selectedSubChartData &&
        selectedSubChartData.bubble &&
        selectedSubChartData.bubble.series &&
        selectedSubChartData.bubble.series.length > 0
      ) {
        const bubbleChartOptions = setBubbleChartOptions(
          selectedSubChartData.bubble
        );
        return (
          <ChartContainer
            chartType="bubble"
            selectedChart={'bubble'}
            options={bubbleChartOptions}
          />
        );
      } else if (
        seriesType === 'lollypop' &&
        selectedSubChartData &&
        selectedSubChartData.lollypop &&
        selectedSubChartData.lollypop.series &&
        selectedSubChartData.lollypop.series.length > 0
      ) {
        const lollipopChartOptions = setLollipopChartOptions(
          selectedSubChartData.lollypop
        );
        return (
          <ChartContainer
            chartType="lollipop"
            selectedChart={'lollipop'}
            options={lollipopChartOptions}
          />
        );
      }
    }
    return <>{'No data to render chart'}</>;
  };
  return renderChartContainer();
};
export default HomeChartComponent;
