import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import edit_pencil from '../images/edit_pencil.svg';
import { Grid, Button, Tooltip } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import { categoryOrder } from '../constants/dashboard';
import { planDetailsByPlanIdSelector } from '../selectors/plannerDetailsSelector';
import { simulatePlanDetailsByPlanIdSuccessAction } from '../redux/actions/plannerDetailsAction';

function VerticalTabs(props) {
  const dispatch = useDispatch();
  const { goals = [], temp = false } = props;
  const [groupedData, setGroupedData] = useState([]);
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );
  const groupByCategory = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const sortCategories = (groupedData, order) => {
    // return order.reduce((sorted, category) => {
    //   if (groupedData[category]) {
    //     sorted[category] = groupedData[category];
    //   }
    //   return sorted;
    // }, {});
    const result = {};
    order.forEach((category) => {
      result[category.value] = groupedData[category.label] || [];
    });
    return result;
  };

  const updatedGroupedObject = (array1, array2, invprops) => {
    // Iterate through array1
    const updatedArray = array1.map((item1) => {
      // Find matching element in array2 based on name
      const matchingItem = array2.find((item2) =>
        !invprops ? item1.name === item2.name : item1.name === item2.prop.name
      );
      if (matchingItem) {
        return {
          ...item1,
          deactivatedInSolution: !invprops
            ? matchingItem.deactivatedInSolution
            : matchingItem.prop.deactivatedInSolution,
        };
      }
      return item1;
    });
    return updatedArray;
  };

  const updateGroupedDataBasedOnPlan = useCallback(
    (sortedGroupedData) => {
      const updatedGroupedData = { ...sortedGroupedData };
      const groupedDataKeys =
        (sortedGroupedData && Object.keys(sortedGroupedData)) || [];
      if (groupedDataKeys.length > 0) {
        groupedDataKeys.forEach((key) => {
          switch (key?.toLowerCase()) {
            case 'kids':
              const { moduleFamily: { infoKids: { kids = [] } = {} } = {} } =
                planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                kids
              );
              break;
            case 'primaryhome':
              const {
                moduleInvestments: { primaryHome: { prop = {} } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                [prop]
              );
              break;
            case 'secondhomes':
              const {
                moduleInvestments: { secondHomes: { props = [] } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                props
              );
              break;
            case 'invprops':
              const {
                moduleInvestments: {
                  invProps: { props: invprops = [] } = {},
                } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                invprops,
                'invprops'
              );
              break;
            case 'vacations':
              const {
                moduleFamily: { infoVacations: { vacations = [] } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                vacations
              );
              break;
            case 'vehicles':
              const {
                moduleFamily: { infoVehicles: { vehicles = [] } = {} } = {},
              } = planDetailsByPlanId || {};
              updatedGroupedData[key] = updatedGroupedObject(
                sortedGroupedData[key],
                vehicles
              );
              break;
            default:
              break;
          }
        });
      }
      return updatedGroupedData;
    },
    [planDetailsByPlanId]
  ); // No dependencies here if it doesn't depend on props/state

  useEffect(() => {
    // const updateGroupedDataBasedOnPlan = (sortedGroupedData) => {
    //   // Implementation of updateGroupedDataBasedOnPlan function
    //   // Ensure to return the updated data
    //   return updatedData;
    // };
    if (
      goals &&
      goals.length > 0 &&
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0
    ) {
      const groupedData = groupByCategory(goals, 'goalCategory');
      const sortedGroupedData = sortCategories(groupedData, categoryOrder);
      const updatedGroupedData =
        updateGroupedDataBasedOnPlan(sortedGroupedData);
      setGroupedData(updatedGroupedData);
    }
  }, [goals, planDetailsByPlanId, updateGroupedDataBasedOnPlan]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Initial state for multiple CheckBoxes
  const [checkedState, setCheckedState] = useState({
    lizCheckBox: true,
    bettyCheckBox: true,
    haggertyCheckBox: true,
    marigoldCheckBox: true,
    mistletoeCheckBox: true,
    aspirationCheckBox: true,
    redwoodCheckBox: true,
    drygulchCheckBox: true,
    porcheCheckBox: true,
    rangeroverCheckBox: true,
    italyCheckBox: true,
    greeceCheckBox: true,
    mcoroccoCheckBox: true,
  });

  const updatePlanDetails = (
    moduleName,
    infoTabName,
    tabName,
    item,
    checked,
    invProps
  ) => {
    const updatedPlanDetails = {
      ...planDetailsByPlanId,
      [moduleName]: {
        ...planDetailsByPlanId[moduleName],
        [infoTabName]: {
          ...planDetailsByPlanId[moduleName][infoTabName],
          [tabName]: planDetailsByPlanId[moduleName][infoTabName][tabName].map(
            (obj) => {
              const { prop = {}, name = '' } = obj;
              const { name: invPropName = '' } =
                Object.keys(prop).length > 0 && prop;
              if (invProps && invPropName === item.name) {
                // Return a new object with deactivatedInSolution updated
                return {
                  ...obj,
                  prop: { ...prop, deactivatedInSolution: !checked },
                };
              } else if (!invProps && name === item.name) {
                return {
                  ...obj,
                  deactivatedInSolution: !checked,
                };
              }
              // Return the original kid object if name does not match
              return obj;
            }
          ),
        },
      },
    };
    dispatch(
      simulatePlanDetailsByPlanIdSuccessAction({
        plannerData: updatedPlanDetails,
      })
    );
  };

  // Handle change for each CheckBox
  const handleActivateCheck = (event, category, item) => {
    const { checked } = event.target;
    //basic approach 1
    // const tempGroupedData = { ...groupedData };

    // const index = tempGroupedData[category]?.indexOf(
    //   tempGroupedData[category]?.find((f) => f.name === item.name)
    // );
    // tempGroupedData[category][index].deactivatedInSolution = checked;
    // setGroupedData(tempGroupedData);

    // approach 2
    // setGroupedData((prevGroupedData) => {
    //   const updatedGroupedData = { ...prevGroupedData };
    //   const categoryItems = updatedGroupedData[category];

    //   if (categoryItems) {
    //     const index = categoryItems.findIndex((f) => f.name === item.name);
    //     if (index !== -1) {
    //       const updatedItem = {
    //         ...categoryItems[index],
    //         deactivatedInSolution: checked,
    //       };

    //       const updatedCategoryItems = [
    //         ...categoryItems.slice(0, index),
    //         updatedItem,
    //         ...categoryItems.slice(index + 1),
    //       ];

    //       updatedGroupedData[category] = updatedCategoryItems;
    //     }
    //   }
    //   return updatedGroupedData;
    // });
    // approach 3
    let tempGroupedData = {
      ...groupedData,
      [category]: groupedData[category].map((categoryItem) => {
        if (categoryItem.name === item.name) {
          return {
            ...categoryItem,
            deactivatedInSolution: !checked,
          };
        }
        return categoryItem;
      }),
    };
    setGroupedData(tempGroupedData);
    switch (category.toLowerCase()) {
      case 'kids':
        const { moduleFamily: { infoKids: { kids = [] } = {} } = {} } =
          planDetailsByPlanId || {};
        // const kidsIndex = kids.findIndex((k) => k.name === item.name);
        // if (kidsIndex !== -1) {
        //   tempPlanDetailsByPlanId.moduleFamily.infoKids.kids[
        //     kidsIndex
        //   ].deactivatedInSolution = checked;
        // }
        // Create a copy of planDetailsByPlanId to avoid mutation
        updatePlanDetails('moduleFamily', 'infoKids', 'kids', item, checked);
        // const updatedPlanDetails = {
        //   ...planDetailsByPlanId,
        //   moduleFamily: {
        //     ...planDetailsByPlanId.moduleFamily,
        //     infoKids: {
        //       ...planDetailsByPlanId.moduleFamily.infoKids,
        //       kids: kids.map((kid) => {
        //         if (kid.name === item.name) {
        //           // Return a new object with deactivatedInSolution updated
        //           return {
        //             ...kid,
        //             deactivatedInSolution: checked,
        //           };
        //         }
        //         // Return the original kid object if name does not match
        //         return kid;
        //       }),
        //     },
        //   },
        // };
        break;
      case 'primaryhome':
        updatePlanDetails(
          'moduleInvestments',
          'primaryHome',
          'prop',
          item,
          checked
        );
        break;
      case 'secondhomes':
        updatePlanDetails(
          'moduleInvestments',
          'secondHomes',
          'props',
          item,
          checked
        );
        break;
      case 'invprops':
        updatePlanDetails(
          'moduleInvestments',
          'invProps',
          'props',
          item,
          checked,
          'invProps'
        );
        break;
      case 'vacations':
        updatePlanDetails(
          'moduleFamily',
          'infoVacations',
          'vacations',
          item,
          checked
        );
        break;
      case 'vehicles':
        updatePlanDetails(
          'moduleFamily',
          'infoVehicles',
          'vehicles',
          item,
          checked
        );
        break;
      default:
        break;
    }

    // setGroupedData((prevGroupedData) => {
    //   return {
    //     ...prevGroupedData,
    //     [category]: prevGroupedData[category].map((categoryItem) => {
    //       if (categoryItem.name === item.name) {
    //         return {
    //           ...categoryItem,
    //           deactivatedInSolution: checked,
    //         };
    //       }
    //       return categoryItem;
    //     }),
    //   };
    // });
  };

  const checkhandleChange = (event) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    });
  };

  const colorHealthFlag = (color = '') => {
    switch (color && color.toLowerCase()) {
      case 'green':
        return 'green_color';
      case 'yellow':
        return 'yellow_color';
      case 'red':
        return 'red_color';
      case 'orange':
        return 'orange_color';
      case 'grey':
      case 'gray':
        return 'grey_color';
      case 'lightgreen':
        return 'lightgreen_color';
      default:
        return 'grey_color';
    }
  };

  return (
    <>
      {!temp ? (
        <div className="solution_goal_verticaltab_main_outer">
          {Object.keys(groupedData).map((category) => (
            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>
                  {categoryOrder.find((f) => f.value === category).label || ''}
                </h4>
              </div>
              <div className="solution_goal_right">
                {groupedData[category]?.map((item) => (
                  <div className="solution_goal_right_row">
                    {category?.toLowerCase() === 'retirement' ? (
                      <div className="solution_goal_column">
                        <h5>Retirement Age</h5>
                        <div className="solution_goal_right_box">
                          <button className="action-limit-width">
                            <img src={edit_pencil} alt="" title="" />
                          </button>
                          <label>{item?.name}</label>
                          <input
                            placeholder="Value"
                            type="text"
                            value={item.retirementAge}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="solution_goal_column">
                        <h5>Include</h5>
                        <div className="solution_goal_right_box">
                          <button className="action-limit-width">
                            <img src={edit_pencil} alt="" title="" />
                          </button>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!item.deactivatedInSolution}
                                onChange={(e) =>
                                  handleActivateCheck(e, category, item)
                                }
                                name={item.name}
                                color="primary"
                              />
                            }
                            label={item.name}
                          />
                        </div>
                      </div>
                    )}
                    <div className="solution_goal_column">
                      <h5>Goal Year</h5>
                      <input
                        placeholder="Year"
                        disabled
                        type="text"
                        title={item.desc}
                        value={item.year}
                      />
                    </div>
                    <div className="solution_goal_column">
                      <h5>Goal Health</h5>
                      <input
                        placeholder=""
                        className={colorHealthFlag(item.healthFlag)}
                        disabled
                        type="text"
                      />
                    </div>
                    {category?.toLowerCase() !== 'retirement' && (
                      <div className="solution_goal_column">
                        <h5>Financial Impact</h5>
                        <input
                          placeholder="Financial Impact"
                          disabled
                          type="text"
                          value={item.financialImpact || '$0'}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {(category?.toLowerCase() !== 'retirement' ||
                  (category?.toLowerCase() === 'primaryhome' &&
                    groupedData[category].length === 0)) && (
                  <div className="solution_goal_right_row add_btn_row">
                    <Button
                      variant="contained"
                      size="Medium"
                      className="add_button"
                    >
                      <img src={pdf_page_plus_icon} alt="" title="" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="solution_goal_verticaltab_main_outer">
            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Retirement</h4>
              </div>
              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Retirement Age</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <label>Stacy</label>
                      <input placeholder="Value" type="text" value="67" />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input
                      placeholder=""
                      className="green_color"
                      disabled
                      type="text"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Retirement Age</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <label>Stacy</label>
                      <input placeholder="67" type="text" value="67" />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input
                      placeholder=""
                      className="green_color"
                      disabled
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Kids</h4>
              </div>

              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.lizCheckBox}
                            onChange={checkhandleChange}
                            name="lizCheckBox"
                            color="primary"
                          />
                        }
                        label="Liz"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input
                      placeholder=""
                      className="yellow_color"
                      disabled
                      type="text"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.bettyCheckBox}
                            onChange={checkhandleChange}
                            name="bettyCheckBox"
                            color="primary"
                          />
                        }
                        label="Betty"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input
                      placeholder=""
                      className="yellow_color"
                      disabled
                      type="text"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="solution_goal_verticaltab_main_outer">
            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Primary Home</h4>
              </div>

              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.haggertyCheckBox}
                            onChange={checkhandleChange}
                            name="haggertyCheckBox"
                            color="primary"
                          />
                        }
                        label="Haggerty"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Second Homes</h4>
              </div>

              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.marigoldCheckBox}
                            onChange={checkhandleChange}
                            name="marigoldCheckBox"
                            color="primary"
                          />
                        }
                        label="Marigold"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.aspirationCheckBox}
                            onChange={checkhandleChange}
                            name="aspirationCheckBox"
                            color="primary"
                          />
                        }
                        label="Aspiration"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Investment Properties</h4>
              </div>

              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.redwoodCheckBox}
                            onChange={checkhandleChange}
                            name="redwoodCheckBox"
                            color="primary"
                          />
                        }
                        label="Redwood"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.mistletoeCheckBox}
                            onChange={checkhandleChange}
                            name="mistletoeCheckBox"
                            color="primary"
                          />
                        }
                        label="Mistletoe"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.drygulchCheckBox}
                            onChange={checkhandleChange}
                            name="drygulchCheckBox"
                            color="primary"
                          />
                        }
                        label="Dry Gulch"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="solution_goal_verticaltab_main_outer">
            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Vehicles </h4>
              </div>

              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.porcheCheckBox}
                            onChange={checkhandleChange}
                            name="porcheCheckBox"
                            color="primary"
                          />
                        }
                        label="Porche"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.rangeroverCheckBox}
                            onChange={checkhandleChange}
                            name="rangeroverCheckBox"
                            color="primary"
                          />
                        }
                        label="Range Rover"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>

                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="solution_goal_content">
              <div className="solution_goal_title">
                <h4>Vacations</h4>
              </div>

              <div className="solution_goal_right">
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.italyCheckBox}
                            onChange={checkhandleChange}
                            name="italyCheckBox"
                            color="primary"
                          />
                        }
                        label="Italy"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>
                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.greeceCheckBox}
                            onChange={checkhandleChange}
                            name="greeceCheckBox"
                            color="primary"
                          />
                        }
                        label="Greece"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>

                <div className="solution_goal_right_row">
                  <div className="solution_goal_column">
                    <h5>Include</h5>
                    <div className="solution_goal_right_box">
                      <button className="action-limit-width">
                        <img src={edit_pencil} alt="" title="" />
                      </button>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState.mcoroccoCheckBox}
                            onChange={checkhandleChange}
                            name="mcoroccoCheckBox"
                            color="primary"
                          />
                        }
                        label="Morocco"
                      />
                    </div>
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Year</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="2024"
                    />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Goal Health</h5>
                    <input placeholder="" disabled type="text" />
                  </div>
                  <div className="solution_goal_column">
                    <h5>Financial Impact</h5>
                    <input
                      placeholder="Value"
                      disabled
                      type="text"
                      value="$(250)k"
                    />
                  </div>
                </div>

                <div className="solution_goal_right_row add_btn_row">
                  <Button
                    variant="contained"
                    size="Medium"
                    className="add_button"
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default VerticalTabs;
