export const GET_PLANS_DETAILS_BY_PLAN_ID = 'plans:details';
export const PLANS_DETAILS_LOADING = 'plans:details:loading';
export const GET_PLANS_DETAILS_ERROR = 'plans:plandetails:error';
export const GET_PLANS_DETAILS_SUCCESS = 'plans:plandetails:success';
export const SAVE_PLAN_DETAILS_BY_PLANID = 'plans:details:save';
export const PLAN_DETAILS_BY_PLANID_ERROR = 'plans:savedetails:error';
export const SAVE_PLAN_DETAILS_BY_PLANID_SUCCESS = 'plans:savedetails:success';
export const CLEAR_PLAN_DETAILS = 'plans:clear:plandetails';
export const SIMULATE_PLAN_DETAILS_BY_PLANID_LOADING =
  'plans:simulate:plandetails:loading';
export const SIMULATE_PLAN_DETAILS_BY_PLANID = 'plans:simulate:plandetails';
export const SIMULATE_PLAN_DETAILS_BY_PLANID_SUCCESS =
  'plans:simulatedetails:success';
export const GET_EXPENSE_DETAILS_BY_LIFESTYLE_SUCCESS =
  'plans:expensedetails:bylifestyle:success';
export const RESET_EXPENSEDETAILS_BY_LIFESTYLE =
  'plans:reset:expensedetails:bylifestyle:success';
export const TERM_LIFE_INSURANCE_PRODUCTS_SUCCESS =
  'solutions:termLife:success';
export const WHOLE_LIFE_INSURANCE_PRODUCTS_SUCCESS =
  'solutions:wholeLife:success';
export const LONG_TERM_CARE_INSURANCE_PRODUCTS_SUCCESS =
  'solutions:ltc:success';

export const PROPERTY_INSURANCE_PRODUCTS_SUCCESS = 'solutions:property:success';
export const AUTO_INSURANCE_PRODUCTS_SUCCESS = 'solutions:auto:success';
export const UMBRELLA_INSURANCE_PRODUCTS_SUCCESS = 'solutions:umbrella:success';
export const SET_PLAN_DETAILS_SIMULATED = 'plan:details:simulated';
export const SET_BEFORE_AFTER_ASSET_MIX = 'before:after:asset:mix';
export const GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID =
  'recommended:asset:allocation:client:id';
export const GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_SUCCESS =
  'recommended:asset:allocation:client:id:success';
export const GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_ERROR =
  'recommended:asset:allocation:client:id:error';
export const GET_ASSET_ALLOCATION_BY_PRODUCT_ID = 'asset:allocation:product:id';
export const GET_ASSET_ALLOCATION_BY_PRODUCT_ID_SUCCESS =
  'asset:allocation:product:id:success';
export const GET_ASSET_ALLOCATION_BY_PRODUCT_ID_ERROR =
  'asset:allocation:product:id:error';
export const SET_ASSET_ALLOCATION_BY_PRODUCT_ID_LOADING_ACTION =
  'asset:allocation:product:id:loading:action';
export const GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID =
  'investment:products:client:id';
export const GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_SUCCESS =
  'investment:products:client:id:success';
export const GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_ERROR =
  'investment:products:client:id:error';
export function setBeforeAfterAssetMixAction(payload) {
  return {
    type: SET_BEFORE_AFTER_ASSET_MIX,
    payload,
  };
}
export function setPlanDetailsSimulatedAction(payload) {
  return {
    type: SET_PLAN_DETAILS_SIMULATED,
    payload,
  };
}
export function getInvestmentProductsByClientIdAction(payload) {
  return {
    type: GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID,
    payload,
  };
}
export function getInvestmentProductsByClientIdSuccessAction(payload) {
  return {
    type: GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_SUCCESS,
    payload,
  };
}
export function getInvestmentProductsByClientIdErrorAction(payload) {
  return {
    type: GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID_ERROR,
    payload,
  };
}
export function getRecommendedAssetAllocListByClientId(payload) {
  return {
    type: GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID,
    payload,
  };
}
export function getRecommendedAssetAllocListByClientIdErrorAction(payload) {
  return {
    type: GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_ERROR,
    payload,
  };
}
export function getRecommendedAssetAllocListByClientIdSuccessAction(payload) {
  return {
    type: GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID_SUCCESS,
    payload,
  };
}

export function getAssetAllocationByProductId(payload) {
  return {
    type: GET_ASSET_ALLOCATION_BY_PRODUCT_ID,
    payload,
  };
}
export function getAssetAllocationByProductIdErrorAction(payload) {
  return {
    type: GET_ASSET_ALLOCATION_BY_PRODUCT_ID_ERROR,
    payload,
  };
}
export function getAssetAllocationByProductIdSuccessAction(payload) {
  return {
    type: GET_ASSET_ALLOCATION_BY_PRODUCT_ID_SUCCESS,
    payload,
  };
}
export function setAssetAllocationByProductIdLoadingAction(payload) {
  return {
    type: SET_ASSET_ALLOCATION_BY_PRODUCT_ID_LOADING_ACTION,
    payload,
  };
}

export function setPlansDetailsLoadingAction(payload) {
  return {
    type: PLANS_DETAILS_LOADING,
    payload,
  };
}
export function getPlansDetailsAction(payload) {
  return {
    type: GET_PLANS_DETAILS_BY_PLAN_ID,
    payload,
  };
}

export function getPlanDetailsSuccessAction(payload) {
  return {
    type: GET_PLANS_DETAILS_SUCCESS,
    payload,
  };
}

export function getPlanDetailsErrorAction(payload) {
  return {
    type: GET_PLANS_DETAILS_ERROR,
    payload,
  };
}

export function saveAndUpdatePlanDetailsByPlanId(payload) {
  return {
    type: SAVE_PLAN_DETAILS_BY_PLANID,
    payload,
  };
}

export function saveAndUpdatePlanDetailsByPlanIdSuccessAction(payload) {
  return {
    type: SAVE_PLAN_DETAILS_BY_PLANID_SUCCESS,
    payload,
  };
}

export function planDetailsByPlanIdErrorAction(payload) {
  return {
    type: PLAN_DETAILS_BY_PLANID_ERROR,
    payload,
  };
}

export function clearPlanDetailsFromStore(payload) {
  return {
    type: CLEAR_PLAN_DETAILS,
    payload,
  };
}

export function setSimulatePlansDetailsLoadingAction(payload) {
  return {
    type: SIMULATE_PLAN_DETAILS_BY_PLANID_LOADING,
    payload,
  };
}

export function simulatePlanDetailsByPlanId(payload) {
  return {
    type: SIMULATE_PLAN_DETAILS_BY_PLANID,
    payload,
  };
}

export function simulatePlanDetailsByPlanIdSuccessAction(payload) {
  return {
    type: SIMULATE_PLAN_DETAILS_BY_PLANID_SUCCESS,
    payload,
  };
}

export function getExpenseDetailsByLifestyleSuccess(payload) {
  return {
    type: GET_EXPENSE_DETAILS_BY_LIFESTYLE_SUCCESS,
    payload,
  };
}

export function resetExpenseDetailsByLifeStyle() {
  return {
    type: RESET_EXPENSEDETAILS_BY_LIFESTYLE,
  };
}

export function termLifeInsuranceProductsSuccessAction(payload) {
  return {
    type: TERM_LIFE_INSURANCE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function wholeLifeInsuranceProductsSuccessAction(payload) {
  return {
    type: WHOLE_LIFE_INSURANCE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function longTermCareInsuranceProductsSuccessAction(payload) {
  return {
    type: LONG_TERM_CARE_INSURANCE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function umbrellaInsuranceProductsSuccessAction(payload) {
  return {
    type: UMBRELLA_INSURANCE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function propertyInsuranceProductsSuccessAction(payload) {
  return {
    type: PROPERTY_INSURANCE_PRODUCTS_SUCCESS,
    payload,
  };
}

export function autoInsuranceProductsSuccessAction(payload) {
  return {
    type: AUTO_INSURANCE_PRODUCTS_SUCCESS,
    payload,
  };
}
