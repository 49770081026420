import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { renderTypes } from '../../constants/dashboard';

class TabsComponent extends Component {
  render() {
    const { renderType, updateRenderType } = this.props;

    return (
      <div className="chart_filter">
        <ul className="tabs">
          {renderTypes.map((m) => {
            return (
              <li className={renderType === m ? 'tab active' : 'tab'}>
                <button onClick={(e) => updateRenderType(e, m)}>{m}</button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default TabsComponent;
