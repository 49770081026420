import {
  renderMembersInputRow,
  renderDualSelectOption,
  renderMemberLabelRow,
  validateMemberNames,
  renderSelectOption,
  validateForm,
  transformInfoCoreBackToUIType,
  getAndUpdatePensionContribYrs,
} from '../../helpers/ClientHelper';
import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Dialog, DialogTitle, DialogActions } from '@mui/material'; // Import dialog components
import ConfirmModal from '../shared/ConfirmModal';
import CloseIcon from '@material-ui/icons/Close';
import Controls from '../controls/Controls';
import MuiAlert from '@mui/material/Alert';
import { validateAgeFieId } from '../../utils/validators';
import { infoCoreValidatableFields } from '../../constants/Client';

export const CoreComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setEditStateValues,
    taxStateOptions,
    taxStatusOptions,
    genderMenuItems,
    setLastStepInParent,
    setValidationError,
  } = props;
  const { memberOne, membersCount } = state;
  const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
  const [infoCoreDetails, setinfoCoreDetails] = useState(infoCore);
  const [taxOptions, setTaxOptions] = useState([]);
  // const memoizedDependencyObject = useMemo(
  //   () => localPlanDetailsByPlanId,
  //   [localPlanDetailsByPlanId]
  // );
  useEffect(() => {
    if (taxStatusOptions && taxStatusOptions.length > 0) {
      const Options =
        membersCount === 2
          ? taxStatusOptions.filter((item) => item.value !== 'Single')
          : membersCount === 1
          ? taxStatusOptions.filter((item) => item.value === 'Single')
          : taxStatusOptions;
      setTaxOptions(Options);
    }
  }, [taxStatusOptions, membersCount]);

  useEffect(() => {
    if (infoCore && Object.keys(infoCore).length > 0) {
      //let tempInfoCore = getAndUpdatePensionContribYrs(infoCore, membersCount);
      let tempInfoCore = transformInfoCoreBackToUIType(infoCore);
      let validationErrors = {};
      validationErrors = validateForm(
        tempInfoCore,
        membersCount,
        infoCoreValidatableFields
      );
      const { validationObj1 = [], validationObj2 = [] } = validationErrors;
      if (
        Object.values(validationObj1).length > 0 ||
        Object.values(validationObj2).length > 0
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
      setinfoCoreDetails(tempInfoCore);
    }
  }, [localPlanDetailsByPlanId, infoCore, membersCount, setValidationError]);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [nameToDelete, setNameToDelete] = useState('');
  const [memberToDelete, setMemberToDelete] = useState('');
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [validatorMessage, setValidatorMessage] = useState('');
  const [alertMessageOnAgeChange, setAlertMessageOnAgeChange] = useState('');
  const [addMemberModalFormDetails, setAddMemberModalFormDetails] = useState({
    name: '',
    isnameValid: true,
    age: '',
    isageValid: true,
    gender: '',
    retirementAge: '',
    isretirementAgeValid: true,
    lockedInSolution: false,
  });
  const [modalType, setModalType] = useState('edit');
  const openDeleteConfirmation = (name, memberToDelete) => {
    setIsDeleteConfirmationOpen(true);
    setNameToDelete(name);
    setMemberToDelete(memberToDelete);
  };

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  useEffect(() => {
    if (alertMessageOnAgeChange) {
      const timer = setTimeout(() => {
        setAlertMessageOnAgeChange('');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [alertMessageOnAgeChange]);

  function deleteInsuredMember(
    insuranceModule,
    numInsName,
    insuranceType,
    memberNameToDelete
  ) {
    const insurances = insuranceModule[insuranceType] || {};

    // Find all indexes of insurance objects with the specified name
    const indexesToDelete = insurances.reduce((acc, ins, index) => {
      if (ins.insured === memberNameToDelete) {
        acc.push(index);
      }
      return acc;
    }, []);

    // Reverse the array of indexes to avoid modification issues
    const reversedIndexes = indexesToDelete.reverse();

    // Remove the objects at the found indexes and reduce the count
    reversedIndexes.forEach((index) => {
      insurances.splice(index, 1);
      insuranceModule[numInsName]--;
    });

    // Update the insurance module in tabInsModule
    insuranceModule[insuranceType] = insurances;
  }

  // Function to close the confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  // Function to handle item deletion after confirmation
  const handleDeleteConfirmation = (memberToDelete) => {
    closeDeleteConfirmation();
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    let tab = 'moduleFamily',
      subTab = 'infoCore';
    let rothTab = 'moduleEstate',
      rothSubTab = 'rothChoices';
    let insTab = 'moduleInsurance',
      termSubTab = 'termLife',
      wholeSubTab = 'wholeLife',
      longSubLifeTab = 'longTermCare';
    // Dynamically fetch the moduleTab object based on tab
    const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
    const tabRothModule = tempPlanDetailsByPlanId[rothTab] || {}; // Tab will be module estate
    const tabInsModule = tempPlanDetailsByPlanId[insTab] || {}; // Tab will be module estate
    let memberOneModule = tabModule[subTab]
      ? tabModule[subTab]['basicsFirst'] || {}
      : {};
    let memberTwoModule = tabModule[subTab]
      ? tabModule[subTab]['basicsSecond'] || {}
      : {};

    let defCompFirst = tabModule[subTab]
      ? tabModule[subTab]['defCompFirst'] || {}
      : {};
    let defCompSecond = tabModule[subTab]
      ? tabModule[subTab]['defCompSecond'] || {}
      : {};

    let eqCompFirst = tabModule[subTab]
      ? tabModule[subTab]['eqCompFirst'] || {}
      : {};
    let eqCompSecond = tabModule[subTab]
      ? tabModule[subTab]['eqCompSecond'] || {}
      : {};

    let incSavFirst = tabModule[subTab]
      ? tabModule[subTab]['incSavFirst'] || {}
      : {};
    let incSavSecond = tabModule[subTab]
      ? tabModule[subTab]['incSavSecond'] || {}
      : {};

    let retChoicesFirst = tabModule[subTab]
      ? tabModule[subTab]['retChoicesFirst'] || {}
      : {};
    let retChoicesSecond = tabModule[subTab]
      ? tabModule[subTab]['retChoicesSecond'] || {}
      : {};

    let rothChoicesFirst = tabRothModule[rothSubTab]
      ? tabRothModule[rothSubTab]['first'] || {}
      : {};
    let rothChoicesSecond = tabRothModule[rothSubTab]
      ? tabRothModule[rothSubTab]['second'] || {}
      : {};

    let memberNameToDelete = '';
    if (memberToDelete === 'basicsSecond') {
      memberNameToDelete = memberTwoModule['name'];
      memberTwoModule = {};
      defCompSecond = {};
      eqCompSecond = {};
      incSavSecond = {};
      retChoicesSecond = {};
      rothChoicesSecond = {};
    }
    if (memberToDelete === 'basicsFirst') {
      memberNameToDelete = memberOneModule['name'];
      memberOneModule = memberTwoModule;
      defCompFirst = defCompSecond;
      eqCompFirst = eqCompSecond;
      incSavFirst = incSavSecond;
      retChoicesFirst = retChoicesSecond;
      rothChoicesFirst = rothChoicesSecond;
      memberTwoModule = {};
      defCompSecond = {};
      eqCompSecond = {};
      incSavSecond = {};
      retChoicesSecond = {};
      rothChoicesSecond = {};
    }

    let termIns = tabInsModule[termSubTab]
      ? tabInsModule[termSubTab] || {}
      : {};
    deleteInsuredMember(
      termIns,
      'numTermLifeIns',
      'termLifeIns',
      memberNameToDelete
    );

    let wholeIns = tabInsModule[wholeSubTab]
      ? tabInsModule[wholeSubTab] || {}
      : {};
    deleteInsuredMember(
      wholeIns,
      'numWholeLifeIns',
      'wholeLifeIns',
      memberNameToDelete
    );

    let longTermIns = tabInsModule[longSubLifeTab]
      ? tabInsModule[longSubLifeTab] || {}
      : {};
    deleteInsuredMember(longTermIns, 'numLtcIns', 'ltcIns', memberNameToDelete);

    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      [tab]: {
        ...tabModule,
        [subTab]: {
          ...tabModule[subTab],
          basicsFirst: memberOneModule,
          defCompFirst: defCompFirst,
          eqCompFirst: eqCompFirst,
          incSavFirst: incSavFirst,
          retChoicesFirst: retChoicesFirst,
          defCompSecond: defCompSecond,
          eqCompSecond: eqCompSecond,
          incSavSecond: incSavSecond,
          retChoicesSecond: retChoicesSecond,
          basicsSecond: memberTwoModule,
          taxFilingStatus:
            Object.keys(memberTwoModule).length > 0 ? 'MFJ' : 'Single',
        },
      },
      [rothTab]: {
        ...tabRothModule,
        [rothSubTab]: {
          ...tabRothModule[rothSubTab],
          first: rothChoicesFirst,
          second: rothChoicesSecond,
        },
      },
      [insTab]: {
        ...tabInsModule,
        [termSubTab]: termIns,
        [wholeSubTab]: wholeIns,
        [longSubLifeTab]: longTermIns,
      },
    };
    setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId, true);
  };

  const filterOutIsValidPropertiesFromFormDetailsModal = () => {
    return Object.keys(addMemberModalFormDetails).reduce((acc, key) => {
      if (!key.startsWith('is')) {
        acc[key] = addMemberModalFormDetails[key];
      }
      return acc;
    }, {});
  };

  const handleAddMember = () => {
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    const { moduleFamily: { infoCore = {} } = {}, moduleFamily = {} } =
      tempPlanDetailsByPlanId;
    let tempInfoCore = { ...infoCore };
    const tempAddMemberModalFormDetails =
      filterOutIsValidPropertiesFromFormDetailsModal();
    const {
      basicsFirst = {},
      basicsSecond = {},
      defCompFirst = {},
      defCompSecond = {},
      eqCompFirst = {},
      eqCompSecond = {},
      incSavFirst = {},
      incSavSecond = {},
      retChoicesFirst = {},
      retChoicesSecond = {},
    } = infoCore;

    if (modalType === 'add') {
      const objectsToCopy = {
        basics: { first: basicsFirst, second: basicsSecond },
        defComp: { first: defCompFirst, second: defCompSecond },
        eqComp: { first: eqCompFirst, second: eqCompSecond },
        incSav: { first: incSavFirst, second: incSavSecond },
        retChoices: { first: retChoicesFirst, second: retChoicesSecond },
      };
      for (const key in objectsToCopy) {
        const { first, second } = objectsToCopy[key];
        for (const property in first) {
          second[property] = '';
        }
        tempInfoCore[`${key}Second`] = second;
      }
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleFamily: {
          ...moduleFamily,
          infoCore: {
            ...tempInfoCore,
            basicsSecond: { ...tempAddMemberModalFormDetails },
            taxFilingStatus: 'MFJ',
          },
        },
      };
    } else {
      if (memberOne) {
        tempInfoCore = {
          ...tempInfoCore,
          basicsFirst: { ...tempAddMemberModalFormDetails },
        };
      } else {
        tempInfoCore = {
          ...tempInfoCore,
          basicsSecond: { ...tempAddMemberModalFormDetails },
        };
      }
      tempInfoCore = getAndUpdatePensionContribYrs(tempInfoCore, membersCount);
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleFamily: {
          ...moduleFamily,
          infoCore: {
            ...tempInfoCore,
          },
        },
      };
    }
    setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId, true);
    setEditStateValues(false, memberOne ? 'basicsFirst' : 'basicsSecond');
    handleAddMemberModalOpen(false);
  };

  const handleAddMemberFormFieldChange = (event, from) => {
    const { value = '' } = event && event.target;
    let isValid = true;
    let validatorMsg = '';
    switch (from && from.toLowerCase()) {
      case 'name':
        validatorMsg = validateMemberNames(
          value,
          'moduleFamily',
          'infoCore',
          localPlanDetailsByPlanId,
          memberOne ? 'basicsSecond' : 'basicsFirst'
        );
        isValid = validatorMsg ? false : true;
        break;
      case 'age':
      case 'retirementage':
        isValid = validateAgeFieId(value, addMemberModalFormDetails, from);
        break;
      case 'gender':
        isValid = true;
        break;
      default:
        break;
    }
    setAddMemberModalFormDetails((old) => ({
      ...old,
      [from]: isValid ? value.replace(/\s+/g, ' ') : value,
      [`is${from}Valid`]: isValid,
    }));
    if (from === 'name') {
      setValidatorMessage(validatorMsg);
    }
    if (from === 'age' || from === 'retirementAge') {
      setAlertMessageOnAgeChange(
        'Any Change made to current age or retirement age will reset pension information that may need your attention!'
      );
    } else {
      setAlertMessageOnAgeChange('');
    }
  };

  const isAddMemberFormValid = () => {
    const {
      name,
      age,
      retirementAge,
      isnameValid,
      isageValid,
      isretirementAgeValid,
    } = addMemberModalFormDetails || {};
    return !(
      name &&
      age &&
      retirementAge &&
      // validatorMessage === '' &&
      isnameValid &&
      isageValid &&
      isretirementAgeValid
    );
  };
  const renderAddNewMemberDetails = () => {
    const {
      name = '',
      age = '',
      retirementAge = '',
      gender = '',
      isnameValid = true,
      isageValid = true,
      isretirementAgeValid = true,
    } = addMemberModalFormDetails || {};
    return (
      <>
        <div className="signup-title-container">
          <span className="signup-title">
            {modalType === 'add' ? 'Add New Member' : 'Update Member'}
          </span>
          <CloseIcon
            className="signup-title-close-icon"
            onClick={() => handleAddMemberModalOpen(false)}
          />
        </div>
        <div className="signup-body-container">
          <Grid container className="newplan-form-grid" spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Controls.Input
                label="Member Name"
                value={name}
                type="text"
                onChange={(event) => {
                  handleAddMemberFormFieldChange(event, 'name');
                }}
                errorText={
                  name ? validatorMessage : 'This is a mandatory field'
                }
                isValid={isnameValid}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controls.Input
                label="Age"
                value={(!isNaN(age) && parseInt(age)) || ''}
                type="number"
                max="120"
                min="18"
                maxLength="3"
                onChange={(event) => {
                  handleAddMemberFormFieldChange(event, 'age');
                }}
                required
                isValid={isageValid}
                errorText={age ? 'Invalid Age' : 'This is a mandatory field'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controls.Select
                name="clientType"
                label="Gender"
                value={gender?.toLowerCase()}
                options={genderMenuItems.map((c) => {
                  return {
                    value: c.value?.toLowerCase(),
                    label: c.label, //taking value cos, label is not present in this response
                  };
                })}
                onChange={(event) =>
                  handleAddMemberFormFieldChange(event, 'gender')
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controls.Input
                label="Retirement Age"
                value={(!isNaN(retirementAge) && parseInt(retirementAge)) || ''}
                type="number"
                max="120"
                min="18"
                maxLength="3"
                onChange={(event) => {
                  handleAddMemberFormFieldChange(event, 'retirementAge');
                }}
                isValid={isretirementAgeValid}
                errorText={
                  retirementAge
                    ? 'Invalid Retirement Age'
                    : 'This is a mandatory field'
                }
                required
              />
            </Grid>
          </Grid>
          {alertMessageOnAgeChange && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MuiAlert
                  severity="warning"
                  sx={{ width: '100%', backgroundColor: '#fdf0e6 !important' }}
                >
                  <p>{alertMessageOnAgeChange}</p>
                </MuiAlert>
              </Grid>
            </Grid>
          )}
        </div>
      </>
    );
  };

  const handleAddMemberModalOpen = (val, member) => {
    const memberInfo = infoCore[member] || {};
    setAddMemberModalOpen(val);
    if (!member) {
      setAddMemberModalFormDetails({
        name: '',
        age: '',
        gender: '',
        retirementAge: '',
        isnameValid: true,
        isageValid: true,
        isretirementAgeValid: true,
      });
      setModalType('add');
      setEditStateValues(false, 'basicsFirst');
      setEditStateValues(false, 'basicsSecond');
    } else {
      const {
        age = '',
        retirementAge = '',
        gender = '',
        name = '',
        lockedInSolution = false,
      } = memberInfo;
      setAddMemberModalFormDetails((old) => ({
        ...old,
        name: name,
        age: age,
        gender: gender,
        retirementAge: retirementAge,
        lockedInSolution: lockedInSolution,
      }));
      setModalType('edit');
    }
  };

  return (
    <>
      <div className="insured_main_outer residence_taxFiling_outer">
        {renderDualSelectOption(
          'text',
          'ddlResidenceState',
          'residenceState',
          'Residence State',
          taxStateOptions,
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'ddlRetirementState',
          'retirementState',
          'Retirement State',
          state
        )}
        {renderSelectOption(
          'text',
          'ddlTaxFilingStatus',
          'taxFilingStatus',
          'Tax Filing Status',
          taxOptions,
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          true
        )}
      </div>
      <div className="insured_main_outer">
        {renderMemberLabelRow(
          infoCoreDetails,
          'name',
          'basicsFirst',
          'basicsSecond',
          setEditStateValues,
          state,
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          openDeleteConfirmation,
          handleAddMemberModalOpen
        )}
      </div>
      <div className="insured_main_outer">
        {renderMembersInputRow(
          'text',
          'tlMemberOneAge',
          'age',
          'tlMemberTwoAge',
          'Age at the end of last year',
          'number',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'basicsFirst',
          'basicsSecond',
          state,
          setinfoCoreDetails
        )}

        {renderMembersInputRow(
          'text',
          'tlMemberOneGender',
          'gender',
          'tlMemberTwoGender',
          'Gender',
          'text',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'basicsFirst',
          'basicsSecond',
          state,
          setinfoCoreDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlMemberOneRetirementAge',
          'retirementAge',
          'tlMemberRetirementAge',
          'Age at Retirement',
          'number',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'basicsFirst',
          'basicsSecond',
          state,
          setinfoCoreDetails
        )}
      </div>
      {addMemberModalOpen && (
        <ConfirmModal
          content={renderAddNewMemberDetails()}
          open={addMemberModalOpen}
          setOpen={handleAddMemberModalOpen}
          onConfirm={handleAddMember}
          okLabel={modalType === 'add' ? 'Add' : 'Update'}
          size="sm"
          isOkButtonDisabled={isAddMemberFormValid()}
        />
      )}
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>
          Are you sure you want to delete "{nameToDelete}"?
        </DialogTitle>
        <DialogActions>
          <button onClick={closeDeleteConfirmation} className="common-button">
            Cancel
          </button>
          <button
            className="common-button"
            onClick={() => handleDeleteConfirmation(memberToDelete)}
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoreComponent;
