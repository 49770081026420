import { put, call, takeLatest } from 'redux-saga/effects';
import {
  GET_PLANS_DETAILS_BY_PLAN_ID,
  getPlanDetailsSuccessAction,
  getPlanDetailsErrorAction,
  setPlansDetailsLoadingAction,
  SAVE_PLAN_DETAILS_BY_PLANID,
  setSimulatePlansDetailsLoadingAction,
  GET_ASSET_ALLOCATION_BY_PRODUCT_ID,
  GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID,
  // saveAndUpdatePlanDetailsByPlanIdErrorAction,
  saveAndUpdatePlanDetailsByPlanIdSuccessAction,
  clearPlanDetailsFromStore,
  SIMULATE_PLAN_DETAILS_BY_PLANID,
  simulatePlanDetailsByPlanIdSuccessAction,
  planDetailsByPlanIdErrorAction,
  setAssetAllocationByProductIdLoadingAction,
  getAssetAllocationByProductIdErrorAction,
  getAssetAllocationByProductIdSuccessAction,
  getRecommendedAssetAllocListByClientIdErrorAction,
  getRecommendedAssetAllocListByClientIdSuccessAction,
  getInvestmentProductsByClientIdSuccessAction,
  getInvestmentProductsByClientIdErrorAction,
  GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID,
} from '../actions/plannerDetailsAction';
import {
  selectSavedPlanByPlanId,
  selectSavedPlanByPlanIdV2,
  saveAndUpdatePlanDetailsByPlanId,
  simulatePlanDetailsByPlanId,
  simulatePlanDetailsByPlanIdV2,
  saveAndUpdatePlanDetailsByPlanIdV2,
  selectAssetAllocationByProductId,
  selectRecommendedAssetAllocationByClientId,
  selectInvestmentProductsByClientId,
} from '../../services/dashboardSvc';
import {
  clearOutputResponseAction,
  getOutputResponseByPlanIdSuccessAction,
} from '../actions/outputResponseDetailsAction';
import { transformResponsePayload } from '../transformers/apiToClientResponseTransformer';
import { setSelectedNumberOfRuns } from '../actions/plansAction';
import { resetSimulateBeforeAfterValues } from '../actions/simulateBeforeAfterAction';
import { transformBackToAPIType } from '../../helpers/ClientHelper';

function* triggerGetInvestmentProductsByClientId(action) {
  try {
    const { status, data } = yield call(
      selectInvestmentProductsByClientId,
      action.payload
    );
    if (status === 200) {
      const investmentProducts = data.map((d) => {
        return { productType: d.productType, tickers: d.element };
      });
      yield put(
        getInvestmentProductsByClientIdSuccessAction(investmentProducts)
      );
    } else {
      yield put(getInvestmentProductsByClientIdErrorAction([]));
    }
  } catch (err) {
    yield put(getInvestmentProductsByClientIdErrorAction([]));
  }
}

function* triggerGetRecommendedAssetAllocationByClientId(action) {
  try {
    //yield put(setAssetAllocationByProductIdLoadingAction(true));
    const { status, data } = yield call(
      selectRecommendedAssetAllocationByClientId,
      action.payload
    );
    if (status === 200) {
      const recommendedAssetAllocation = data
        .filter((a) => a.riskLevelId)
        .map((d) => {
          return {
            label: d.riskLevelLabel,
            value: d.riskLevelId,
            riskLevel: d.riskLevel,
          };
        });
      // yield put(setAssetAllocationByProductIdLoadingAction(false));
      yield put(
        getRecommendedAssetAllocListByClientIdSuccessAction(
          recommendedAssetAllocation
        )
      );
    } else {
      // yield put(setAssetAllocationByProductIdLoadingAction(false));
      yield put(getRecommendedAssetAllocListByClientIdErrorAction([]));
    }
  } catch (err) {
    // yield put(setAssetAllocationByProductIdLoadingAction(false));
    yield put(getRecommendedAssetAllocListByClientIdErrorAction([]));
  }
}
function* triggerGetAssetAllocationByProductId(action) {
  try {
    yield put(setAssetAllocationByProductIdLoadingAction(true));
    const { status, data } = yield call(
      selectAssetAllocationByProductId,
      action.payload
    );
    if (status === 200) {
      yield put(setAssetAllocationByProductIdLoadingAction(false));
      yield put(getAssetAllocationByProductIdSuccessAction(data));
    } else {
      yield put(setAssetAllocationByProductIdLoadingAction(false));
      yield put(getAssetAllocationByProductIdErrorAction({}));
    }
  } catch (err) {
    yield put(setAssetAllocationByProductIdLoadingAction(false));
    yield put(getAssetAllocationByProductIdErrorAction({}));
  }
}
function* triggerGetPlanDetailsByPlanId(action) {
  try {
    yield put(clearPlanDetailsFromStore()); // search for this
    yield put(setPlansDetailsLoadingAction(true));
    const {
      payload: { planId, version },
    } = action;
    const { status, data } = yield call(selectSavedPlanByPlanIdV2, planId);
    if (status === 200) {
      //yield put(setPlansDetailsLoadingAction(false));
      yield put(getPlanDetailsSuccessAction(data));
    } else {
      yield put(setPlansDetailsLoadingAction(false));
      yield put(getPlanDetailsErrorAction({}));
    }
  } catch (err) {
    yield put(setPlansDetailsLoadingAction(false));
    yield put(getPlanDetailsErrorAction({}));
  }
}

function* triggerSavePlanDetailsByPlanId(action) {
  try {
    yield put(setSimulatePlansDetailsLoadingAction(true));
    const {
      payload: { version, ...rest },
    } = action; // sending version from calling fn, so had to use ...rest for planDetailsByPlanId as it is named differently at different places
    const { status, data } = yield call(
      version === 'v2'
        ? saveAndUpdatePlanDetailsByPlanIdV2
        : saveAndUpdatePlanDetailsByPlanId,
      Object.values(rest)[0] // gives planDetailsByPlanId Object
    );
    if (status === 200) {
      yield put(setSimulatePlansDetailsLoadingAction(false));
      yield put(
        saveAndUpdatePlanDetailsByPlanIdSuccessAction({
          plannerData: Object.values(rest)[0],
        })
      );
    } else {
      yield put(setSimulatePlansDetailsLoadingAction(false));
      yield put(planDetailsByPlanIdErrorAction(true));
    }
  } catch (err) {
    yield put(setSimulatePlansDetailsLoadingAction(false));
    yield put(planDetailsByPlanIdErrorAction(true));
  }
}

function* triggerSimulatePlanDetailsByPlanId(action) {
  const {
    chrono,
    plannerFormReq,
    displayTermChanged,
    isIndvClient,
    version,
    from,
  } = action.payload;
  try {
    yield put(setSimulatePlansDetailsLoadingAction(true));
    if (!from) {
      // this is for goals and insights tab to make sure right section is not emptied while simulating
      yield put(clearOutputResponseAction());
    }
    yield put(resetSimulateBeforeAfterValues());
    const updatedPlannerFormReq = transformBackToAPIType(plannerFormReq);
    const { status, data } = yield call(
      version ? simulatePlanDetailsByPlanIdV2 : simulatePlanDetailsByPlanId,
      updatedPlannerFormReq
    );
    if (status === 200) {
      yield put(
        simulatePlanDetailsByPlanIdSuccessAction({
          plannerData: updatedPlannerFormReq,
        })
      );
      // if (!isIndvClient) {
      yield put(
        getOutputResponseByPlanIdSuccessAction({
          simulateBeforeData: displayTermChanged ? data.chrono : { ...chrono }, // revisit, commented this out as we are not getting data on load
          //simulateBeforeData: data.chrono,
          simulateOutputResponse: data,
        })
      );
      yield put(setSimulatePlansDetailsLoadingAction(false));
      yield put(setPlansDetailsLoadingAction(false));
      // }
    } else {
      yield put(setSimulatePlansDetailsLoadingAction(false));
      yield put(setPlansDetailsLoadingAction(false));
      yield put(planDetailsByPlanIdErrorAction(true));
      yield put(
        getOutputResponseByPlanIdSuccessAction({
          simulateBeforeData: {},
          simulateOutputResponse: {},
        })
      );
    }
  } catch (err) {
    yield put(setSimulatePlansDetailsLoadingAction(false));
    yield put(setPlansDetailsLoadingAction(false));
    yield put(planDetailsByPlanIdErrorAction(true));
    yield put(
      getOutputResponseByPlanIdSuccessAction({
        simulateBeforeData: {},
        simulateOutputResponse: {},
      })
    );
  }
}

export function* watchPlannerDetailsByPlanID() {
  yield takeLatest(GET_PLANS_DETAILS_BY_PLAN_ID, triggerGetPlanDetailsByPlanId);
  yield takeLatest(SAVE_PLAN_DETAILS_BY_PLANID, triggerSavePlanDetailsByPlanId);
  yield takeLatest(
    SIMULATE_PLAN_DETAILS_BY_PLANID,
    triggerSimulatePlanDetailsByPlanId
  );
  yield takeLatest(
    GET_ASSET_ALLOCATION_BY_PRODUCT_ID,
    triggerGetAssetAllocationByProductId
  );
  yield takeLatest(
    GET_RECOMMENDED_ASSET_ALLOCATION_BY_CLIENT_ID,
    triggerGetRecommendedAssetAllocationByClientId
  );
  yield takeLatest(
    GET_INVESTMENT_PRODUCTS_BY_CLIENT_ID,
    triggerGetInvestmentProductsByClientId
  );
}
